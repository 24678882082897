<template>
  <b-container>
    <b-row v-if="!loading">
      <b-col>
        <b-card>
          <b-container>
            <b-row>
              <b-col>
          <p><b-link :to="{ name: 'Extsources' }">back to external sources</b-link></p>
          <h2>
            <b-button class="mr-2" v-if="permission" variant="outline-primary" :to="{ name: 'ExtsourceEdit', params: { id: row.id }}">Edit</b-button>
            {{row.name}}
          </h2>
          <div><markdown :content="row.comments" /></div>
          <div class="mb-4">link: <a :href="row.link" target="_blank">{{row.link}}</a></div>

           <!-- reg sources -->
          <span v-if="!loadingRegbites">
          <div><h4>Regulatory quotes</h4></div>
                <span v-for="row in regbites" :key="row.id">
                  <strong><b-link :to="{ name: 'Regbite', params: { id: row.id }}">{{row.name}}</b-link></strong>
                  <div>
                    <markdown :content="row.comments" />
                  </div>
                </span>
          </span>

              </b-col>
            </b-row>

          </b-container>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import _ from 'lodash'
import ac from '../libs/accesscontrol'
import Markdown from '@/components/Markdown.vue'

export default {
  name: 'Extsource',
  beforeDestroy () {
  },
  components: {
    Markdown
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'external source', action: 'open external source', model: 'extsource', model_id: this.$route.params.id })
    this.permission = ac.can(this.user.acgroups).createAny('extsource').granted
    this.loadRows()
  },
  data () {
    return {
      loading: true,
      loadingRegbites: true,
      permission: false,
      regbites: '',
      row: ''
    }
  },
  methods: {
    loadRows: async function () {
      this.$logger.debug('loadRisks started')
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = `/extsource/${this.$route.params.id}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.row = response
        this.loading = false
      } catch (e) {
        this.$logger.warn('/extsource/:id error ', e)
      }
      // get quotes
      try {
        let apiName = 'cosmos'
        // let path = `/vertex/${this.$route.params.id}/events/edge/in/inExtsource/vertices`
        let path = `/standard/relation/regbite/${this.$route.params.id}/extsources/extsources.id/regbites.del`
        let response2 = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('loadEdges OK', response2)
        this.regbites = _.sortBy(response2, ['name'])
        this.loadingRegbites = false
      } catch (e) {
        this.$logger.warn('error getting extsources', e)
      }
    }
  },
  mounted () {
  },
  watch: {
  }
}
</script>

<style>
</style>
